import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/images/leftnav-bg-grad.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_q_drawer = _resolveComponent("q-drawer")!

  return (_openBlock(), _createBlock(_component_q_drawer, {
    width: 225,
    modelValue: _ctx.leftDrawerOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.leftDrawerOpen) = $event))
  }, {
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "drawer-vault-logo" }, null, -1)),
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_0,
        class: "drawer-top-img"
      }, null, -1)),
      _createVNode(_component_q_btn, {
        flat: "",
        dense: "",
        round: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickleftDrawer())),
        "aria-label": "Menu",
        icon: _ctx.chevronChoice,
        class: "drawer-toggle-btn"
      }, null, 8, ["icon"]),
      _createVNode(_component_Navigation)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}