import { computed, defineComponent, onMounted, ref } from "vue";
import i18n from "@/i18n";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import { GET_TOP_NAVIGATION } from "@/services/top-navigation-service"
import { TopNavigationModel } from "@/models/topNavigation";
import { currentSiteId, mediakiwi } from "@/extensions/extra";
import store from "@/store";
import { returnErrors } from "@/utils/returnErrors";
import { checkCookie } from "@/utils/checkCookie";
import { useCookies } from "vue3-cookies";

export default defineComponent({
  name: "TopNavigation",
  props: {
    items: {
      type: String,
      required: true,
      default: "mediakiwi",
    },
  },
  components: {},

  setup(props) {
    const $q = useQuasar();
    const topNavigationModel = new TopNavigationModel();
    const originalUrlValue = computed(
      () => `${props.items}`
    );
    const temp = ref([]);
    const groupType = ref(null);
    const path = checkURL();

    onMounted(() => {
      getTopNavigation();
    });

    async function getTopNavigation() {
      topNavigationModel.currentSiteId = currentSiteId;
      topNavigationModel.originalUrl = originalUrlValue.value;
      const [error, response] = await GET_TOP_NAVIGATION(topNavigationModel, groupType.value)
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
      } else {
        for (let i = 0; i < response.items.length; i++) {
          let jsonPath = response.items[i].href;
          jsonPath = jsonPath.replace(mediakiwi, "");
          jsonPath = jsonPath.substring(jsonPath.indexOf("/") + 1);
          jsonPath = jsonPath.split("/", 1)[0];
          if (jsonPath == path) {
            temp.value = response.items[i].items;
          }
        }
      }
      store.state.loadingSpinner = false;
    }

    function checkURL() {
      let path = window.location.pathname;
      path = path.substring(path.indexOf("/") + 1);
      path = path.split("/", 1)[0];
      return path;
    }

    return {
      topNavigationModel,
      getTopNavigation,
      originalUrlValue,
      groupType,
      list: temp,
    }
  },

})

