import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/images/vaultN-logo.svg'


const _hoisted_1 = { class: "media-device-not-supported-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12 q-mt-xl" }
const _hoisted_6 = { class: "media-device-not-supported-ellipse" }
const _hoisted_7 = { class: "text1" }
const _hoisted_8 = { class: "text2" }
const _hoisted_9 = { class: "text3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-4" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-12 margin-top-100" }, [
            _createElementVNode("img", { src: _imports_0 })
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("login.screenResolutionText1")), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("login.screenResolutionText2")), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("login.screenResolutionText3")), 1)
            ])
          ])
        ])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-4" }, null, -1))
    ])
  ]))
}