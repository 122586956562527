import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Login",
      component: () => import("@/views/login/Login.vue"),
      meta: {
        title: "Please login to continue",
        requiresVisitor: true,
      },
    },
    {
      path: "/oidc-callback",
      name: "OidcCallback",
      component: () => import("@/views/oidc-callback/OidcCallback.vue"),
      meta: {
        title: "oidc call back",
        requiresVisitor: true,
      },
    },
    {
      path: "/home/dashboard/",
      name: "HomeDashboard",
      component: () => import("@/views/home/dashboard/Dashboard.vue"),
      meta: {
        title: "Home",
        requiresAuth: true,
      },
    },
    {
      path: "/2-fa-authenticate/",
      name: "2FAAuthenticate",
      component: () => import("@/views/2-fa-authenticate/2FAAuthenticate.vue"),
      meta: {
        title: "You need to authenticate",
        requiresVisitor: true,
      },
    },
    {
      path: "/home",
      redirect: "/home/dashboard/",
    },
    {
      path: "/reset-password/",
      name: "ResetPassword",
      component: () => import("@/views/reset-password/ResetPassword.vue"),
      meta: {
        title: "Reset password via email",
        requiresVisitor: true,
      },
    },
    {
      path: "/set-password/:guid/:email",
      name: "SetPassword",
      component: () => import("@/views/set-password/SetPassword.vue"),
      meta: {
        title: "Define new password",
        requiresVisitor: true,
      },
    },
    {
      path: "/create-account/:type/:guid",
      name: "CreateAccount",
      component: () => import("@/views/create-account/CreateAccount.vue"),
      meta: {
        requiresAuth: true,
        title: "Create new VaultN account",
      },
    },
    {
      path: "/create-account-company/viguid/:guid",
      name: "CreateAccountCompany",
      component: () =>
        import("@/views/create-account-company/CreateAccountCompany.vue"),
      meta: {
        requiresVisitor: true,
        title: "Create new VaultN account",
      },
    },
    {
      path: "/create-account-Registered/",
      name: "CreateAccountRegistered",
      component: () =>
        import("@/views/create-account-registered/CreateAccountRegistered.vue"),
      meta: {
        requiresVisitor: true,
        title: "Account created",
      },
    },
    {
      path: "/my_vault/dashboard/",
      name: "MyVaultDashboard",
      component: () => import("@/views/my_vault/dashboard/Dashboard.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/dashboard" },
      meta: {
        title: "My Vault",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/assets",
      redirect: "/my_vault/assets/",
    },
    {
      path: "/my_vault/assets/",
      name: "MyVaultAssets",
      component: () => import("@/views/my_vault/assets/Assets.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/assets" },
      meta: {
        title: "My Vault - Assets",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/shared-assets",
      redirect: "/my_vault/shared-assets/",
    },
    {
      path: "/my_vault/shared-assets/",
      name: "MyVaultSharedAssets",
      component: () =>
        import("@/views/my_vault/shared-assets/SharedAssets.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/shared-assets" },
      meta: {
        title: "My Vault - Assets",
        requiresAuth: true,
      },
    },
    {
      path: "/email-extract/:id",
      name: "MyVaultAssetsExtractUrl",
      component: () =>
        import("@/views/my_vault/assets-extract-url/AssetsExtractUrl.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/assets" },
      meta: {
        title: "My Vault - Assets",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/products",
      redirect: "/my_vault/products/",
    },
    {
      path: "/my_vault/products/",
      name: "MyVaultProducts",
      component: () => import("@/views/my_vault/products/Products.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/products" },
      meta: {
        title: "My Vault - Products",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/orders",
      redirect: "/my_vault/orders/",
    },
    {
      path: "/my_vault/orders/",
      name: "MyVaultOrders",
      component: () => import("@/views/my_vault/orders/Orders.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/orders" },
      meta: {
        title: "My Vault - Orders",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/orders/:id",
      redirect: "/my_vault/orders/:id",
    },
    {
      path: "/my_vault/orders/:id",
      name: "MyVaultOrderDetails",
      component: () =>
        import("@/views/my_vault/order-details/OrderDetails.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/order-details" },
      meta: {
        title: "My Vault - Order Details",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/products-add/",
      name: "MyVaultProductsAdd",
      component: () => import("@/views/my_vault/products-add/ProductsAdd.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/products" },
      meta: {
        title: "My Vault - Add Product",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/products-edit/:id",
      name: "MyVaultProductsEdit",
      component: () =>
        import("@/views/my_vault/products-edit/ProductsEdit.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/products" },
      meta: {
        title: "My Vault - Edit Product",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/my_transactions",
      redirect: "/my_vault/transaction/",
    },
    {
      path: "/my_vault/transaction/",
      name: "MyVaultTransaction",
      component: () => import("@/views/my_vault/transactions/Transactions.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/my_transactions" },
      meta: {
        title: "My Vault - Transaction Report",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/blacklistings",
      redirect: "/my_vault/blacklistings/",
    },
    {
      path: "/my_vault/blacklistings/",
      name: "MyVaultBlacklistings",
      component: () =>
        import("@/views/my_vault/blacklistings/Blacklistings.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/blacklistings" },
      meta: {
        title: "My Vault - Blacklistings",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/transaction-detail/:id",
      name: "MyVaultTransactionDetail",
      component: () =>
        import("@/views/my_vault/transaction-detail/TransactionDetail.vue"),
      props: { navigationUrl: "/mediakiwi/reports/transaction_report" },
      meta: {
        title: "My Vault - Agreementflow Transactions",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/transaction-detail/:id/:ownerGuid/",
      name: "MyVaultTransactionDetailOwner",
      component: () =>
        import(
          "@/views/my_vault/transaction-detail-owner/TransactionDetailOwner.vue"
        ),
      props: { navigationUrl: "/mediakiwi/reports/transaction_report" },
      meta: {
        title: "My Vault - Agreementflow Transactions Details",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/transfers",
      redirect: "/my_vault/inbox/",
    },
    {
      path: "/my_vault/inbox/",
      name: "MyVaultInbox",
      component: () => import("@/views/my_vault/inbox/Inbox.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/transfers" },
      meta: {
        title: "My Vault - Inbox",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/stock_outbox",
      redirect: "/my_vault/outbox/",
    },
    {
      path: "/my_vault/outbox/",
      name: "MyVaultOutbox",
      component: () => import("@/views/my_vault/outbox/Outbox.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/stock_outbox" },
      meta: {
        title: "My Vault - Outbox",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/my_allocations",
      redirect: "/my_vault/my-allocations/",
    },
    {
      path: "/my_vault/my-allocations/",
      name: "MyVaultMyAllocations",
      component: () =>
        import("@/views/my_vault/my-allocations/MyAllocations.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/my_allocations" },
      meta: {
        title: "My Vault - My Allocations",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/allocated",
      redirect: "/my_vault/allocated/",
    },
    {
      path: "/my_vault/allocated/",
      name: "MyVaultAllocated",
      component: () => import("@/views/my_vault/allocated/Allocated.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/allocated" },
      meta: {
        title: "My Vault - Allocated",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_network/connections",
      redirect: "/my_network/connections/",
    },
    {
      path: "/my_network/connections/",
      name: "MyNetworkConnections",
      component: () => import("@/views/my_network/connections/Connections.vue"),
      props: { navigationUrl: "/mediakiwi/my_network/connections" },
      meta: {
        title: "My Network - Connections",
        requiresAuth: true,
      },
    },
    {
      path: "/my_network/connections-type-guid/:type/:guid",
      name: "MyNetworkConnectionsTypeGuid",
      component: () =>
        import(
          "@/views/my_network/connections-type-guid/ConnectionsTypeGuid.vue"
        ),
      props: { navigationUrl: "/mediakiwi/my_network/connections" },
      meta: {
        title: "My Network - Connections",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/settings/account",
      redirect: "/settings/account/",
    },
    {
      path: "/settings/account/",
      name: "SettingsAccount",
      component: () => import("@/views/settings/account/Account.vue"),
      props: { navigationUrl: "/mediakiwi/settings/account" },
      meta: {
        title: "Settings - Account",
        requiresAuth: true,
      },
    },
    {
      path: "/settings/account/:guid/:action",
      name: "SettingsAccountAuthentication",
      component: () =>
        import(
          "@/views/settings/account-authentication/AccountAuthentication.vue"
        ),
      props: { navigationUrl: "/mediakiwi/settings/account" },
      meta: {
        title: "Settings - Account",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/settings/users",
      redirect: "/settings/users/",
    },
    {
      path: "/settings/users/",
      name: "SettingsUsers",
      component: () => import("@/views/settings/users/Users.vue"),
      props: { navigationUrl: "/mediakiwi/settings/users" },
      meta: {
        title: "Settings - Users",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/settings/my_certificates",
      redirect: "/settings/certificate/",
    },
    {
      path: "/settings/certificate/",
      name: "SettingsCertificate",
      component: () => import("@/views/settings/certificate/Certificate.vue"),
      props: { navigationUrl: "/mediakiwi/settings/my_certificates" },
      meta: {
        title: "Settings - Certificate",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/settings/webhooks",
      redirect: "/settings/webhooks/",
    },
    {
      path: "/settings/webhooks/",
      name: "SettingsWebhooks",
      component: () => import("@/views/settings/webhooks/Webhooks.vue"),
      props: { navigationUrl: "/mediakiwi/settings/webhooks" },
      meta: {
        title: "Settings - Webhooks",
        requiresAuth: true,
      },
    },
    {
      path: "/settings/account-edit/",
      name: "SettingsAccountEdit",
      component: () => import("@/views/settings/account-edit/AccountEdit.vue"),
      props: { navigationUrl: "/mediakiwi/settings/account" },
      meta: {
        title: "Settings - Account Edit",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/settings/documentation",
      redirect: "/settings/documentation/",
    },
    {
      path: "/settings/documentation/",
      name: "SettingsDocumentation",
      component: () =>
        import("@/views/settings/documentation/Documentation.vue"),
      props: { navigationUrl: "/mediakiwi/settings/documentation" },
      meta: {
        title: "Settings - Documentation",
        requiresAuth: true,
      },
    },
    {
      path: "/settings/documentation-add/",
      name: "SettingsDocumentationAdd",
      component: () =>
        import("@/views/settings/documentation-add/DocumentationAdd.vue"),
      props: { navigationUrl: "/mediakiwi/settings/documentation?item=0" },
      meta: {
        title: "Settings - Add Article",
        requiresAuth: true,
      },
    },
    {
      path: "/settings/documentation-edit/:id",
      name: "SettingsDocumentationEdit",
      component: () =>
        import("@/views/settings/documentation-edit/DocumentationEdit.vue"),
      props: { navigationUrl: "/mediakiwi/settings/documentation?item=0" },
      meta: {
        title: "Settings - Edit Documentation",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/finance/transactions",
      redirect: "/finance/transactions/",
    },
    {
      path: "/finance/transactions/",
      name: "FinanceTransactions",
      component: () => import("@/views/finance/transactions/Transactions.vue"),
      props: { navigationUrl: "/mediakiwi/finance/transactions" },
      meta: {
        title: "Finance - Transactions",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/finance/price_lists",
      redirect: "/finance/price-lists/",
    },
    {
      path: "/finance/price-lists/",
      name: "FinancePriceLists",
      component: () => import("@/views/finance/price-lists/PriceLists.vue"),
      props: { navigationUrl: "/mediakiwi/finance/price_lists" },
      meta: {
        title: "Finance - Price Lists",
        requiresAuth: true,
      },
    },
    {
      path: "/finance/price-lists-add/",
      name: "FinancePriceListsAdd",
      component: () =>
        import("@/views/finance/price-lists-add/PriceListsAdd.vue"),
      props: { navigationUrl: "/mediakiwi/finance/price_lists" },
      meta: {
        title: "Finance - Add Price Lists",
        requiresAuth: true,
      },
    },
    {
      path: "/finance/price-lists-edit/:id",
      name: "FinancePriceListsEdit",
      component: () =>
        import("@/views/finance/price-lists-edit/PriceListsEdit.vue"),
      props: (route) => ({
        navigationUrl: "/mediakiwi/finance/price_lists",
      }),
      meta: {
        title: "Finance - Edit Price List",
        requiresAuth: true,
      },
    },
    {
      path: "/finance/price-lists-copy/:id",
      name: "FinancePriceListsCopy",
      component: () =>
        import("@/views/finance/price-lists-copy/PriceListsCopy.vue"),
      props: (route) => ({
        navigationUrl: "/mediakiwi/finance/price_lists",
      }),
      meta: {
        title: "Finance - Copy Price List",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/settings/jit_endpoints",
      redirect: "/settings/jit-endpoints/",
    },
    {
      path: "/settings/jit-endpoints/",
      name: "SettingsJitEndpoints",
      component: () =>
        import("@/views/settings/jit-endpoints/JitEndpoints.vue"),
      props: { navigationUrl: "/mediakiwi/settings/jit_endpoints" },
      meta: {
        title: "Settings - JIT Endpoints",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/settings/markets",
      redirect: "/settings/markets/",
    },
    {
      path: "/settings/markets/",
      name: "SettingsMarkets",
      component: () => import("@/views/settings/markets/Markets.vue"),
      props: { navigationUrl: "/mediakiwi/settings/markets" },
      meta: {
        title: "Settings - Markets",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/finance/promotions",
      redirect: "/finance/promotions/",
    },
    {
      path: "/finance/promotions/",
      name: "FinancePromotions",
      component: () => import("@/views/finance/promotions/Promotions.vue"),
      props: { navigationUrl: "/mediakiwi/finance/promotions" },
      meta: {
        title: "Finance - Promotions",
        requiresAuth: true,
      },
    },
    {
      path: "/finance/promotions-add/",
      name: "FinancePromotionsAdd",
      component: () =>
        import("@/views/finance/promotions-add/PromotionsAdd.vue"),
      props: { navigationUrl: "/mediakiwi/finance/promotions" },
      meta: {
        title: "Finance - Add Promotion",
        requiresAuth: true,
      },
    },
    {
      path: "/finance/promotions-edit/:id",
      name: "FinancePromotionsEdit",
      component: () =>
        import("@/views/finance/promotions-edit/PromotionsEdit.vue"),
      props: { navigationUrl: "/mediakiwi/finance/promotions" },
      meta: {
        title: "Finance - Edit Promotion",
        requiresAuth: true,
      },
    },
    {
      path: "/finance/promotions-copy/:id",
      name: "FinancePromotionsCopy",
      component: () =>
        import("@/views/finance/promotions-copy/PromotionsCopy.vue"),
      props: { navigationUrl: "/mediakiwi/finance/promotions" },
      meta: {
        title: "Finance - Copy Promotion",
        requiresAuth: true,
      },
    },
    {
      path: "/finance/dashboard/",
      name: "FinanceDashboard",
      component: () => import("@/views/finance/dashboard/Dashboard.vue"),
      props: { navigationUrl: "/mediakiwi/finance/dashboard" },
      meta: {
        title: "Finance",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/finance/reports",
      redirect: "/finance/report-list/",
    },
    {
      path: "/finance/report-list/",
      name: "ReportList",
      component: () => import("@/views/finance/report-list/ReportList.vue"),
      props: { navigationUrl: "/mediakiwi/finance/reports" },
      meta: {
        title: "Finance - Report List",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/stockpool",
      redirect: "/my_vault/stockpool-in/",
    },
    {
      path: "/my_vault/stockpool-in/",
      name: "MyVaultStockpoolIn",
      component: () => import("@/views/my_vault/stockpool-in/StockPoolIn.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/stockpool" },
      meta: {
        title: "My Vault - Stockpool",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/stockpool-out/",
      name: "MyVaultStockpoolOut",
      component: () =>
        import("@/views/my_vault/stockpool-out/StockPoolOut.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/stockpool" },
      meta: {
        title: "My Vault - Stockpool",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/regioncodes",
      redirect: "/my_vault/regions/",
    },
    {
      path: "/my_vault/regions/",
      name: "MyVaultRegions",
      component: () => import("@/views/my_vault/regions/Regions.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/regions" },
      meta: {
        title: "My Vault - Regions",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/regions-add/",
      name: "MyVaultRegionsAdd",
      component: () => import("@/views/my_vault/regions-add/RegionsAdd.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/regions" },
      meta: {
        title: "My Vault - Add Region",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/regions-edit/:id",
      name: "MyVaultRegionsEdit",
      component: () => import("@/views/my_vault/regions-edit/RegionsEdit.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/regions" },
      meta: {
        title: "My Vault - Edit Region",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/regions-copy/:id",
      name: "MyVaultRegionsCopy",
      component: () => import("@/views/my_vault/regions-copy/RegionsCopy.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/regions" },
      meta: {
        title: "My Vault - Copy Region",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/uploads",
      redirect: "/my_vault/uploads/",
    },
    {
      path: "/my_vault/uploads/",
      name: "MyVaultUploads",
      component: () => import("@/views/my_vault/uploads/Uploads.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/uploads" },
      meta: {
        title: "My Vault - Uploads",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/uploads-detail/:id",
      name: "MyVaultUploadsDetail",
      component: () =>
        import("@/views/my_vault/uploads-detail/UploadsDetail.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/uploads" },
      meta: {
        title: "My Vault - Upload Details",
        requiresAuth: true,
      },
    },
    {
      path: "/mediakiwi/my_vault/sharing",
      redirect: "/my_vault/sharing/",
    },
    {
      path: "/my_vault/sharing/",
      name: "MyVaultSharing",
      component: () => import("@/views/my_vault/sharing/Sharing.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/sharing" },
      meta: {
        title: "My Vault - Sharing",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/create-rule/",
      name: "MyVaultCreateRule",
      component: () => import("@/views/my_vault/create-rule/CreateRule.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/create-rule" },
      meta: {
        title: "My Vault - Create Rule",
        requiresAuth: true,
      },
    },
    {
      path: "/Relationship/:type/:guid",
      name: "MyNetworkRelationship",
      component: () =>
        import(
          "@/views/my_network/relationship/Relationship.vue"
        ),
      props: { navigationUrl: "/mediakiwi/my_network/relationship" },
      meta: {
        title: "My Network - Relationship",
        requiresVisitor: true,
        isPublic: true,
      },
    },
    {
      path: "/my_vault/share-rules/",
      name: "NyVaultShareRules",
      component: () => import("@/views/my_vault/share-rules/ShareRules.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/share-rules" },
      meta: {
        title: "My Vault - Share Rule",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/edit-rule/:id",
      name: "MyVaultEditRule",
      component: () => import("@/views/my_vault/edit-rule/EditRule.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/edit-rule" },
      meta: {
        title: "My Vault - Edit Rule",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/connection-details/:id",
      name: "MyVaultConnection",
      component: () =>
        import("@/views/my_vault/connection-details/ConnectionDetails.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/connection-details" },
      meta: {
        title: "My Vault - Connection Details",
        requiresAuth: true,
      },
    },
    {
      path: "/my_vault/product-details/:id",
      name: "MyVaultSharingProduct",
      component: () =>
        import("@/views/my_vault/sharing-product-details/SharingProductDetails.vue"),
      props: { navigationUrl: "/mediakiwi/my_vault/product-details" },
      meta: {
        title: "My Vault - Product Details",
        requiresAuth: true,
      },
    },
  ],
});

export default router;
