import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/images/company-circle.png'


const _hoisted_1 = { class: "profile-main-container" }
const _hoisted_2 = { class: "profile-brand" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "profile-avatar-container" }
const _hoisted_5 = { class: "avatar" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "dialog-bubble" }
const _hoisted_8 = { class: "dialog-bubble-content" }
const _hoisted_9 = { class: "dialog-header" }
const _hoisted_10 = { class: "text-h5" }
const _hoisted_11 = { class: "dialog-main" }
const _hoisted_12 = { class: "profile-avatar-container" }
const _hoisted_13 = { class: "avatar" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "avatar-info" }
const _hoisted_16 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_header = _resolveComponent("q-header")!

  return (_openBlock(), _createBlock(_component_q_header, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.brandImage,
            alt: "Brand image",
            class: "brand-image"
          }, null, 8, _hoisted_3)
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("img", {
          src: _imports_0,
          class: "profile-company-circle"
        }, null, -1)),
        _createElementVNode("div", {
          class: "profile-container",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogToggle()))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: _ctx.profileImage,
                class: "avatar-image"
              }, null, 8, _hoisted_6),
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "material-icons" }, " arrow_drop_down ", -1))
            ])
          ])
        ])
      ]),
      (_ctx.profileDialog)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "dialog-container",
            onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.dialogToggle()))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "material-icons" }, " arrow_drop_up ", -1)),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("myProfile.definition")), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("img", {
                        src: _ctx.profileImage,
                        class: "avatar-image"
                      }, null, 8, _hoisted_14)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createTextVNode(_toDisplayString(_ctx.userName) + " ", 1),
                      _createElementVNode("sub", null, _toDisplayString(_ctx.userEmail), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_q_btn, {
                    class: "btn btn-dialog-footer text-body2",
                    label: _ctx.$t('settingsBtn.definition'),
                    to: "/mediakiwi/settings/account"
                  }, null, 8, ["label"]),
                  _createVNode(_component_q_btn, {
                    class: "btn btn-dialog-footer margin-left-190 text-body2",
                    label: _ctx.$t('signOut.definition'),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logOut()))
                  }, null, 8, ["label"])
                ])
              ])
            ])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}